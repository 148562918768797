/**
 * Fetcher Setting
 * @author Tevin
 */

import { Fetcher } from '@components/bases/Fetcher';
import MenuPathTree from '@fetchers/datas/MenuPathTree';

export class FSetting extends Fetcher {
    constructor() {
        super({
            urlPrefix: ['/api/setting/', '/Home/Setting/'],
            mockPath: MenuPathTree.projectName,
        });
    }

    // 获取微信公众号域名验证文件专用上传地址（微信公众号）
    getUploadWXVerifyURLSpell() {
        return this.spellURL('../common/uploadFile', 'upload_file');
    }

    // 获取微信支付证书文件专用上传地址（支付证书）
    getUploadWXPaymentURLSpell() {
        return this.spellURL('../common/uploadFile', 'upload_cert');
    }

    // 获取工行支付密钥文件专用上传地址（支付证书）
    getUploadICBCPaymentURLSpell() {
        return this.spellURL('../common/uploadFile', 'upload_yourpri');
    }

    // 获取积分策略（积分策略）
    getScoreStrategy() {
        const url = this.spellURL('getScoreStrategy', 'get_currency');
        const send = {};
        return this.post(url, send);
    }

    // 保存积分策略（积分策略）
    saveScoreStrategy(currency) {
        const url = this.spellURL('saveScoreStrategy', 'set_currency');
        const send = {
            ...currency,
        };
        return this.post(url, send);
    }

    // 获取上层费列表（上楼费）
    getFloorFeeList(search) {
        const url = this.spellURL('getFloorFeeList', 'floor_list');
        const send = {
            ...this.transKeyName('underline', search),
            page: 1,
            limit: 9000,
        };
        return this.post(url, send);
    }

    // 获取上楼费开关状态（上楼费）
    getFloorFeeValve() {
        const url = this.spellURL('getFloorFeeValve', 'floor_status');
        const send = {};
        return this.post(url, send);
    }

    // 保存上楼费开关状态（上楼费）
    saveFloorFeeValve(valve) {
        const url = this.spellURL('saveFloorFeeValve', 'api_floor');
        const send = {
            ...this.transKeyName('underline', valve),
        };
        return this.post(url, send);
    }

    // 保存上楼费信息（上楼费）
    saveFloorFeeInfo(info) {
        const url = this.spellURL('saveFloorFeeInfo', 'api_floor');
        const send = {
            ...this.transKeyName('underline', info),
        };
        return this.post(url, send);
    }

    // 获取配送设置（配送设置）
    getDeliverySetting() {
        const url = this.spellURL('getDeliverySetting', 'dispatch_info');
        const send = {};
        return this.post(url, send);
    }

    // 保存配送设置（配送设置）
    saveDeliverySetting(delivery) {
        const url = this.spellURL('saveDeliverySetting', 'order_dispatch');
        const { pickStore, siteJoin, pickJoin, ...resetDelivery } = delivery;
        const send = {
            ...this.transKeyName('underline', resetDelivery),
            pickStore,
            siteJoin,
            pickJoin,
        };
        return this.post(url, send);
    }

    // 读取临时欠瓶允许的会员类型（配送设置）
    getTempBottleUserType() {
        const url = this.spellURL('getTempBottleUserType', 'bottleMemberType');
        const send = {};
        return this.post(url, send);
    }

    // 保存临时欠瓶允许的会员类型（配送设置）
    saveTempBottleUserType(types) {
        const url = this.spellURL('saveTempBottleUserType', 'bottleMemberType');
        const send = {
            ...types,
        };
        return this.post(url, send);
    }

    // 读取App设置（App设置）
    getAppSettings() {
        const url = this.spellURL('getAppSettings', 'app_menu_get');
        const send = {};
        return this.post(url, send);
    }

    // 读取App设置（App设置）
    saveAppSettings(settings) {
        const url = this.spellURL('saveAppSettings', 'app_menu_set');
        const send = {
            ...this.transKeyName('underline', settings),
        };
        return this.post(url, send);
    }

    // 读取气瓶使用费
    getBottleRoyalties(type) {
        const url = this.spellURL('getBottleRoyalties', 'bottle_royalties');
        const send = {
            ...type,
        };
        return this.post(url, send);
    }

    // 保存气瓶使用费
    saveBottleRoyalties(royalty) {
        const url = this.spellURL('saveBottleRoyalties', 'bottle_royalties');
        const send = {
            ...this.transKeyName('underline', royalty),
        };
        return this.post(url, send);
    }

    // 获取开户须知（开户须知）
    getUserRegAgreement() {
        const url = this.spellURL('getRegAgreement', 'add_user_notice');
        const send = {};
        return this.post(url, send);
    }

    // 保存开户须知（开户须知）
    saveUserRegAgreement(content) {
        const url = this.spellURL('saveRegAgreement', 'add_user_notice');
        const send = {
            notice: content,
        };
        return this.post(url, send);
    }

    // 获取气瓶一瓶一码设置（一瓶一码）
    getBottleUniqueSetting() {
        const url = this.spellURL('getBottleUniqueSetting', '../Gangping/Check_setting');
        const send = {};
        return this.post(url, send, [
            ['', 'companyabstract', 'companyAbstract'],
            ['', 'companyname', 'companyName'],
        ]);
    }

    // 保存气瓶一瓶一码设置（一瓶一码）
    saveBottleCodeSetting(setting) {
        const url = this.spellURL('saveBottleUniqueCode', '../Gangping/Change_setting');
        const send = {
            ...this.transKeyName('underline', this.trimEndsSpace(setting)),
        };
        return this.post(url, send);
    }

    // 获取支付方式设置（支付方式）
    getPaymentMode() {
        const url = this.spellURL('getPaymentMode', 'wxpay_type');
        const send = {};
        return this.post(url, send);
    }

    // 保存支付方式设置（支付方式）
    savePaymentMode(setting) {
        const url = this.spellURL('savePaymentMode', 'wxpay_type_setting');
        const send = {
            ...this.transKeyName('underline', setting),
        };
        return this.post(url, send);
    }

    // 获取微信支付立减区域（支付方式）
    getWxPayModeUseArea() {
        const url = this.spellURL('getWxPayModeUseArea', 'wxpay_voucher_area');
        const send = {};
        return this.post(url, send);
    }

    // 保存微信支付立减区域在线状态（支付方式）
    saveWxPayModeUseAreaOnline(area) {
        const url = this.spellURL('saveWxPayModeUseAreaOnline', 'wxpay_voucher_area');
        const send = {
            ...this.transKeyName('underline', area),
        };
        return this.post(url, send);
    }

    // 保存微信支付立减区域（支付方式）
    saveWxPayModeUseArea(area) {
        const url = this.spellURL('saveWxPayModeUseArea', 'wxpay_voucher_area');
        const send = {
            ...area,
        };
        return this.post(url, send);
    }

    // 获取月结的会员类型（支付方式）
    getMonthPayUserTypes() {
        const url = this.spellURL('getMonthPayUserTypes', 'monthPayMemberType');
        const send = {};
        return this.post(url, send);
    }

    // 保存月结的会员类型（支付方式）
    saveMonthPayUserType(typeData) {
        const url = this.spellURL('saveMonthPayUserType', 'monthPayMemberType');
        const send = {
            ...typeData,
        };
        return this.post(url, send);
    }

    // 获取微信公众号绑定设置（微信公众号）
    getWxOfficialAcc() {
        const url = this.spellURL('getWxOfficialAcc', 'wx_setting');
        const send = {};
        return this.post(url, send);
    }

    // 保存微信公众号绑定设置（微信公众号）
    saveWxOfficialAcc(official) {
        const url = this.spellURL('saveWxOfficialAcc', 'weixin_setting');
        const send = {
            ...this.transKeyName('underline', official),
        };
        return this.post(url, send);
    }

    // 获取微信菜单设置（微信菜单）
    getWxMenuSetting() {
        const url = this.spellURL('getWxMenuSetting', 'wx_menu');
        const send = {};
        return this.post(url, send);
    }

    // 保存微信菜单设置（微信菜单）
    saveWxMenuSetting(setting) {
        const url = this.spellURL('saveWxMenuSetting', 'wx_menu_setting');
        const send = {
            menu: setting,
        };
        return this.post(url, send);
    }

    // 获取微信红包设置（微信红包）
    getWxRedPacketSetting() {
        const url = this.spellURL('getWxRedPacketSetting', '../Hongbao/hongbao_info');
        const send = {};
        return this.post(url, send);
    }

    // 保存微信红包设置（微信红包）
    saveWxRedPacketSetting(redPacket) {
        const url = this.spellURL('saveWxRedPacketSetting', '../Hongbao/saveCfg');
        const send = {
            ...this.transKeyName('underline', redPacket),
        };
        return this.post(url, send);
    }

    // 保存微信红包功能开关状态（微信红包）
    saveWxRedPacketValve(redPacket) {
        const url = this.spellURL('saveWxRedPacketValve', '../Hongbao/hongbao_status');
        const send = {
            ...redPacket,
        };
        return this.post(url, send);
    }

    // 获取微信消息模版列表（微信消息模版）
    getWxMsgTemplates(search, pagination, sorter) {
        const url = this.spellURL('getWxMsgTemplates', 'msg_model_list');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 保存删除微信消息模版（微信消息模版）
    saveDeleteWxMsgTemplate(template) {
        const url = this.spellURL('saveDeleteWxMsgTemplate', 'api_msg_model');
        const send = {
            ...this.transKeyName('underline', template),
        };
        return this.post(url, send);
    }

    // 保存初始化微信消息模版（微信消息模版）
    saveInitWxMsgTemplate(template) {
        const url = this.spellURL('saveWxMsgTemplateInit', 'api_msg_model');
        const send = {
            ...this.transKeyName('underline', template),
        };
        return this.post(url, send);
    }

    // 获取微信模版 ID 列表（筛选项）
    getWxMsgTemplateIdsAsSelect() {
        const url = this.spellURL('getWxMsgTemplateIdFilter', 'msg_model_info');
        const send = {};
        return this.post(url, send).then(res => {
            if (!res) {
                return null;
            }
            return (res.templateList || []).map(item => ({
                id: item.templateId,
                name: item.title,
            }));
        });
    }

    // 保存微信模版信息（微信模版）
    saveWxMsgTemplateInfo(template) {
        const url = this.spellURL('saveWxMsgTemplateInfo', 'api_msg_model');
        const send = {
            ...this.transKeyName('underline', template),
        };
        return this.post(url, send);
    }

    // 读取微信支付证书信息（支付证书）
    getWxPaymentCertificate() {
        const url = this.spellURL('getWxPaymentCertificate', 'get_wpay_detail');
        const send = {};
        return this.post(url, send, [
            ['', 'fapikey', 'apikey'],
            ['', 'fmchid', 'mchid'],
            ['', 'fcertp12', 'certp12'],
            ['', 'fcertpem', 'certpem'],
            ['', 'fkeypem', 'keypem'],
        ]);
    }

    // 保存微信支付证书信息（支付证书）
    saveWxPaymentCertificate(cert) {
        const url = this.spellURL('saveWxPaymentCertificate', 'update_wpay_detail');
        const send = {
            ...cert,
        };
        return this.post(url, send);
    }

    // 获取聚合支付证书信息（支付证书）
    getUnionPayCertificate() {
        const url = this.spellURL('getUnionPayCertificate', 'get_unionpay_detail');
        const send = {};
        return this.post(url, send);
    }

    // 保存聚合支付证书信息（支付证书）
    saveUnionPayCertificate(cert) {
        const url = this.spellURL('saveUnionPayCertificate', 'update_unionpay_detail');
        const send = {
            ...this.transKeyName('underline', cert),
        };
        return this.post(url, send);
    }

    // 获取开户短信配置（开户短信）
    getSMSSetting() {
        const url = this.spellURL('getSMSSetting', 'sms_info');
        const send = {};
        return this.post(url, send);
    }

    // 保存开户短信配置（开户短信）
    saveSMSSetting(sms) {
        const url = this.spellURL('saveSMSSetting', 'sms_setting');
        const { signName, templateCode, ...resetSms } = sms;
        const send = {
            ...resetSms,
            sign_name: signName,
            template_code: templateCode,
        };
        return this.post(url, send);
    }

    // 获取 v8 接口对接设置（V8对接）
    getV8DockingSetting() {
        const url = this.spellURL('getV8DockingSetting', 'v8_info');
        const send = {};
        return this.post(url, send);
    }

    // 保存 v8 接口对接设置（V8对接）
    saveV8DockingSetting(setting) {
        const url = this.spellURL('saveV8DockingSetting', 'v8_setting');
        const send = {
            ...this.transKeyName('underline', setting),
        };
        return this.post(url, send);
    }

    // 获取数据维护上传文件接口
    getDataMaintenanceUploadURL() {
        return this.spellURL('../common/uploadFile', '../Upload/upload');
    }

    // 气瓶档案数据维护接口
    saveMaintainArchive(type) {
        const url = this.spellURL('saveMaintainArchive', '../Gangping/maintainArchive');
        const send = {
            ...this.transKeyName('underline', type),
        };
        return this.post(url, send);
    }

    // 充装数据维护接口
    saveMaintainRecord(type) {
        const url = this.spellURL('saveMaintainRecord', '../Gangping/maintainRecord');
        const send = {
            ...this.transKeyName('underline', type),
        };
        return this.post(url, send);
    }

    // 查询充装数据上传任务执行结果
    getExportArchiveResult(resultId) {
        const url = this.spellURL('getExportArchiveResult', '../Gangping/getExportArchiveResult');
        const send = {
            ...this.transKeyName('underline', resultId),
        };
        return this.post(url, send);
    }
}

export const $fetchSetting = new FSetting();
